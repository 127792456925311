import "./intro.scss";
import React from "react";
import { introPhoto } from "../../data"
import _ from "lodash"
import { Carousel } from "react-bootstrap"


export default function Intro() {


  return (
    <div className="intro" id="intro">
      <div className="wrapper">
          <h1>Nevison Annang</h1>
          <h4> Portrait & Wedding Photographer</h4>
        </div>
      <Carousel className="intro-carousel" controls={false} >
      {introPhoto.map((pack) => (
        <Carousel.Item id={pack.id}>
          <img
            className="d-block w-100 pic"
            src={pack.img}
          />
        </Carousel.Item>
      ))}
    </Carousel>
    </div>
  );
}
