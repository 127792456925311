import React, { useState,  useRef } from "react";
import "./photoPack.scss";
import { photoPackages } from "../../data"
import _ from "lodash"
import { Carousel, Card, Button, Modal } from "react-bootstrap"
import emailjs from '@emailjs/browser';


export default function PhotoPack() {
  const [show, setShow] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [selectedPack, setSelectedPack] = useState(-1);

  const handleClose = () => setShow(false);
  const handleCloseMessage = () => setShowMessage(false);
  const handleShow = packId => {
    setShow(true);
    setSelectedPack(packId-1)
  };

  const handleShowMessage = () => {
    setShow(false);
    setShowMessage(true);
  };

  const form = useRef();
  const [sent, setSent] = useState(false);

  const sendEmail = () => {

    emailjs.sendForm('service_j7yuy5m', 'template_0n0pi1v', form.current, 'KDrLyikS3RKtR3Jjg')
      .then((result) => {
        setShowMessage(false);
      }, (error) => {
        console.log(error.text);
      });
  };


  return (
    <div className="photoPack" id="photoPack">
      <h1>Wedding Photography Packages</h1>

      <Carousel className="pack">
        {_.take(photoPackages, 3).map((pack) => (
          <Carousel.Item onClick={() => handleShow(pack.id)} id={pack.id}>
            <img
              className="d-block w-100 pic"
              src={pack.img}
            />
            <Carousel.Caption>
              <h3>{pack.name}</h3>
              <p><b>{pack.coverage}</b>{pack.shortDesc}</p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>

      <div className="wrapper">
      Your wedding day should be one of the most amazing and memorable days of your life. And as your photographer, I make this my personal goal. We don’t just create images for you, we make sure you enjoy the experience of creation too. We believe in providing quality services and assure you a stress-less, comfortable and fulfilling experience.        </div>

      <div className="d-flex card-list justify-content-center">
        {photoPackages.map((pack) => (
          <Card style={{ width: '14.5rem', minWidth: '14.5rem' }} className="card-item m-1" id={pack.id}>
            <Card.Img variant="top"  className="image-cont" src={pack.img} />
            <Card.Body  className="pre-line">
              <Card.Title>{pack.name}</Card.Title>
              <Card.Text className="text-overline">
                <div><b>{pack.type}</b></div>
                <b>{pack.coverage}</b>{pack.shortDesc}
              </Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted">
              <Button className="button" variant="primary" size="sm" onClick={() => handleShow(pack.id)}>Learn more</Button>
            </Card.Footer>
          </Card>))}
      </div>


      <Modal show={show} onHide={() => handleClose()}>
        <Modal.Header>
          <Modal.Title>{photoPackages[selectedPack]?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            className="d-block w-100 pic"
            src={photoPackages[selectedPack]?.img}
          />
          <b>{photoPackages[selectedPack]?.coverage}</b><br />
          {photoPackages[selectedPack]?.desc}
        </Modal.Body>
        <Modal.Footer>
          <Button className="button" onClick={() => handleShowMessage()}>
            Ask me more
          </Button>
          <Button className="button close" onClick={() => handleClose()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showMessage} onHide={() => handleCloseMessage()}>
        <Modal.Header>
          <Modal.Title>Ask Information for {photoPackages[selectedPack]?.name} Pack</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form ref={form} className="d-flex flex-column">
            <div className="mx-3 mt-3">Name: </div>
            <input placeholder="Name" className="mx-3" type="text" name="user_name" />
            <div className="mx-3 mt-3">Email: </div>
            <input placeholder="Email" className="mx-3" type="email" name="user_email" />
            <input className="d-none" type="email" name="pack" defaultValue={photoPackages[selectedPack]?.name} />
            <div className="mx-3 mt-3">Message: </div>
            <textarea placeholder="Message" className="mx-3" name="message" />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="button" onClick={() => sendEmail()}>
            Send
          </Button>
          <Button className="button close" onClick={() => handleCloseMessage()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
