import "./portfolio.scss";
import { Link } from "react-router-dom";

export default function Portfolio() {
  return (
    <div className="portfolio" id="portfolio">
      <div className="d-flex justify-content-between w-100 align-items-center">
        <div className="title-line"></div>
        <h1>Portfolio</h1>
        <div className="title-line"></div>
      </div>
      <div className="container">
        <div className="d-flex flex-wrap justify-content-center">
            <Link to="/portraits" className="item">
              <img
                src="assets/potraits.jpg"
                alt=""
              />
              <h3>Portraits & Street</h3>
            </Link>
            <Link to="/weddings" className="item">
              <img
                src="assets/photoPack/10.jpg"
                alt=""
              />
              <h3>Weddings</h3>
            </Link>
          <Link to="/brand" className="item">
              <img
                src="assets/brand.jpg"
                alt=""
              />
              <h3>Brand</h3>
              </Link>
        </div>
      </div>
    </div>
  );
}
