import React, { useState, useEffect, useRef, useCallback } from "react";
import { Card, Footer, Header } from "../../styles";
import "./weddings.scss";
import {
  weddings,
} from "../../data";
import Gallery from "react-photo-gallery";
import Carousel, {  ModalGateway } from "react-images";
import _ from "lodash";
import { Button, Modal } from "react-bootstrap"



export default function Weddings() {
  const [expandedId, setExpandedId] = useState(-1);
  const [accodionHeight, setAccodionHeight] = useState(-1);
  const refs = useRef([React.createRef(), React.createRef()]);

  const open = (i) => {
    setExpandedId(expandedId === i ? -1 : i);
    setShow(true);
  }

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setExpandedId(-1)
  }



  const getViews = item => {
    let views = [];
    for (let i = 1; i < item?.picturesCount; i++) {
      views.push({
        src: "assets/weddings/" + item.folder + "/" + i + ".jpg",
        width: _.includes(item.verticalPictures, i)?_.includes(item.format23, i)?2:3:_.includes(item.format23, i)?3:4,
        height: _.includes(item.verticalPictures, i)? _.includes(item.format23, i)? 3 :4:_.includes(item.format23, i)?2:3,
        // srcSet: "assets/weddings/"+item.folder+"/",
        caption: item.title
      })
    }
    return views
  }

  useEffect(() => {
    const getHeight = refs.current[0].current.scrollHeight;
    setAccodionHeight(getHeight + 400);
  }, [expandedId]);

  return (
    <div className="weddings" id="weddings">
      <div className="d-flex justify-content-between w-100 align-items-center">
        <div className="title-line"></div>
        <h1>Weddings</h1>
        <div className="title-line"></div>
      </div>        
      {weddings.map((item, index) => (
        <Card>
          <Header className="card-header">
            <img
              src={item.mainPicture}
              alt=""
              className="main-picture"
            />
            <div>
              <h2>{item.title}</h2>
              <div>{item.description}</div>
            </div>
          </Header>
          <Footer
            onClick={() => open(index)}
            ref={refs.current[index]} key={index}
          >
            <span>View more...</span>

          </Footer>
        </Card>
      ))}

<Modal size="lg" show={show} onHide={() => handleClose()}>
<Modal.Header>
  <Modal.Title>{weddings[expandedId]?.title}</Modal.Title>
</Modal.Header>
<Modal.Body>
{expandedId>-1?<Gallery photos={getViews(weddings[expandedId])} direction={"column"} />
:null}         
</Modal.Body>
<Modal.Footer>
  <Button className="button" onClick={() => handleClose()}>
    Close
  </Button>
</Modal.Footer>
</Modal>  
    </div>
  );
}
