import { useRef,useState } from "react";
import "./contact.scss";
import emailjs from '@emailjs/browser';

export default function Contact() {
  const form = useRef();
  const [sent, setSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_j7yuy5m', 'template_pzo6dmk', form.current, 'KDrLyikS3RKtR3Jjg')
      .then((result) => {
        setSent(true);
      }, (error) => {
          console.log(error.text);
      });
  };
  
  return (
    <div className="contact" id="contact">
    <div className="right">
        <div className="d-flex justify-content-between w-100 align-items-center">
        <div className="title-line"></div>
        <h1>Contact Me</h1>
        <div className="title-line"></div>
      </div>

    <form ref={form} onSubmit={sendEmail}>
      <input placeholder="Name" type="text" name="user_name" />
      <input placeholder="Email" type="email" name="user_email" />
      <textarea placeholder="Message" name="message" />
      <button className="button" type="submit">Send</button>
          {sent && <span>Thanks, I'll reply ASAP :)</span>}
    </form>
    </div>
    </div>
  );
}
