import React, { useState,useRef } from "react";
import "./videoPack.scss";
import { videoPackages } from "../../data"
import _ from "lodash"
import { Carousel, Card, Button, Modal } from "react-bootstrap"
import emailjs from '@emailjs/browser';


export default function VideoPack() {
  const [show, setShow] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [selectedPack, setSelectedPack] = useState(-1);

  const handleClose = () => setShow(false);
  const handleCloseMessage = () => setShowMessage(false);
  const handleShow = packId => {
    setShow(true);
    setSelectedPack(packId-1)
  };

  const handleShowMessage = () => {
    setShow(false);
    setShowMessage(true);
  };

  const form = useRef();

  const sendEmail = () => {

    emailjs.sendForm('service_j7yuy5m', 'template_0n0pi1v', form.current, 'KDrLyikS3RKtR3Jjg')
      .then((result) => {
        setShowMessage(false);
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <div className="videoPack" id="videoPack">
      <h1>Wedding Videography Packages</h1>


      <div className="video-responsive">
    <iframe
      width="853"
      height="480"
      src="https://www.youtube.com/embed/BTrPKZqTFMo"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>

      <div className="wrapper">
        Your wedding day should be one of the most amazing and memorable days of your life; just like the first day you met your spouse. As your videographer I make this my personal goal. We don’t just create images for you, we make sure you enjoy the experience of creation too. We believe in providing quality services and assure you a stress less, comfortable and fulfilling experience.      </div>

      <div className="d-flex card-list">
        {videoPackages.map((pack) => (
          <Card style={{ width: '14.5rem', minWidth: '14.5rem' }} className="card-item m-1" id={pack.id}>
            <Card.Img variant="top" className="image-cont" src={pack.img} />
            <Card.Body className="pre-line">
              <Card.Title>{pack.name}</Card.Title>
              <Card.Text className="text-overline">
                <b>{pack.coverage}</b>{pack.shortDesc}
              </Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted">
              <Button className="button" variant="primary" size="sm" onClick={() => handleShow(pack.id)}>Learn more</Button>
            </Card.Footer>
          </Card>))}
      </div>

      <div className="d-flex w-100 justify-content-center pb-4">
      <a className="icon-container ml-auto" target="_blank" rel="noreferrer" href="mailto:mamotfammedia@gmail.com">
      <i className="fa fa-envelope"></i>
      </a>
      <a className="icon-container mr-auto" target="_blank" rel="noreferrer" href="https://www.youtube.com/c/MamotFammediaFilms/videos">
      <i className="fa fa-youtube"></i>
       </a>
    </div>

            <img className="pack" src="assets/mamot.png"/>

      <Modal show={show} onHide={() => handleClose()}>
        <Modal.Header>
          <Modal.Title>{videoPackages[selectedPack]?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            className="d-block w-100 pic"
            src={videoPackages[selectedPack]?.img}
          />
          <b>{videoPackages[selectedPack]?.coverage}</b><br />
          {videoPackages[selectedPack]?.desc}
        </Modal.Body>
        <Modal.Footer>
          <Button className="button" onClick={() => handleShowMessage()}>
            Ask me more
          </Button>
          <Button className="button close" onClick={() => handleClose()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showMessage} onHide={() => handleCloseMessage()}>
        <Modal.Header>
          <Modal.Title>Ask Information for {videoPackages[selectedPack]?.name} Pack</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form ref={form} className="d-flex flex-column">
            <div className="mx-3 mt-3">Name: </div>
            <input placeholder="Name" className="mx-3" type="text" name="user_name" />
            <div className="mx-3 mt-3">Email: </div>
            <input placeholder="Email" className="mx-3" type="email" name="user_email" />
            <input className="d-none" type="email" name="pack" defaultValue={'Video '+videoPackages[selectedPack]?.name} />
            <div className="mx-3 mt-3">Message: </div>
            <textarea placeholder="Message" className="mx-3" name="message" />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="button" onClick={() => sendEmail()}>
            Send
          </Button>
          <Button className="button close" onClick={() => handleCloseMessage()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
