export const packages = [
  {
    id: 1,
    name: "Videography Packages",
    url: "/video-pack",
    img: "assets/collaborators/6.jpg",
  },
  {
    id: 2,
    name: "Wedding Photography Packages",
    img: "assets/photoPack/1.jpg",
    url: "/photo-pack",
    desc: "Capturing authentic moments with a touch of magic",
    featured: true,
  },
  {
    id: 3,
    name: "Extra",
    img: "assets/extra.jpg",
  },
];

export const videoPackages = [
  {
    id: 1,
    name: "Platinum",
    img: "assets/videoPack/1.jpg",
    coverage: "15h shoot (8am - 11pm)",
    shortDesc: " - 10-20 minute Creative Film - USB drive",
    desc: `Two Shooters\n
15hrs Shoot (8am - 11pm)\n
10 - 20 minute Creative Film\n
1-2 minute Teaser\n
Full-length Ceremony & Reception Edit\n
High Definition Delivery on USB - shipping Expenses on Couples`,
  },
  {
    id: 2,
    name: "Gold",
    img: "assets/videoPack/2.jpg",
    coverage: "14h shoot (8am - 10pm)",
    shortDesc: " - 10-20 minute Creative Film - USB drive",
    desc: `Two Shooters\n
14hrs Shoot (8am - 10pm)\n
10 - 20 minute Creative Film\n
1-2 minute Teaser\n
Reception entrance + 8 min reception dance\n
High Definition Delivery on USB - shipping Expenses on Couples`,
  },
  {
    id: 3,
    name: "Silver",
    img: "assets/photoPack/2.jpg",
    coverage: "14h shoot (8am - 10pm)",
    shortDesc: " - 10 minute Creative Film - USB drive",
    desc: `Two Shooters\n
14hrs Shoot (8am - 10pm)\n
10 minute Creative Film\n
High Definition Delivery on USB - shipping Expenses on Couples`,
  },
  {
    id: 4,
    name: "Bronze",
    img: "assets/photoPack/3.jpg",
    coverage: "14h shoot (8am - 10pm)",
    shortDesc: " - 3-5 minute Creative Film - USB drive",
    desc: `Two Shooters\n
14hrs Shoot (8am - 10pm)\n
3-5 minute Creative Highlights Film\n
High Definition Delivery on USB - shipping Expenses on Couples`,
  },
  {
    id: 4,
    name: "Engagement",
    img: "assets/photoPack/5.jpg",
    coverage: "2h shoot",
    shortDesc: " - 3-5 minute Creative Film",
    desc: `Up to 2 hours shoot\n
3-5 minute Creative Highlights Film`,
  },
];

export const introPhoto = [
  {
    id: 1,
    img: "assets/photoPack/8.jpg",
  },
  {
    id: 2,
    img: "assets/photoPack/2.jpg",
  },
  {
    id: 3,
    img: "assets/photoPack/3.jpg",
  },
  {
    id: 4,
    img: "assets/photoPack/4.jpg",
  },
  {
    id: 5,
    img: "assets/photoPack/6.jpg",
  },
  {
    id: 6,
    img: "assets/photoPack/9.jpg",
  },
  
];

export const photoPackages = [
  {
    id: 1,
    name: "Luxury",
    img: "assets/photoPack/8.jpg",
    coverage: "15h coverage",
    shortDesc: " - 500-800 edited images",
    type: "White Wedding",
    desc: `Prep. Shoot
Ceremony coverage (Church)
Couple & wedding train shoot
Reception coverage
500-800 edited images
50 high-end retouching
1 Photobook (35 pages) with best-chosen images
2 USB drive shipped to couple and both families within Italy`,
  },
  {
    id: 2,
    name: "Classic",
    img: "assets/photoPack/1.jpg",
    coverage: "14h coverage",
    shortDesc: " - 300-400 edited images",
    type: "White Wedding",
    desc: `14hrs Shoot
Prep. Shoot
Ceremony coverage (Church)
Couple & wedding train shoot
Reception coverage
300-400 edited images
35 high-end retouching`,
  },
  {
    id: 3,
    name: "Regular",
    img: "assets/photoPack/11.jpg",
    coverage: "12h coverage",
    shortDesc: " - 200 edited images",
    type: "White Wedding",
    desc: `12hrs Shoot
Prep. Shoot
Ceremony coverage (Church)
Couple & wedding train shoot
Reception coverage
200 edited images
20 high-end retouching`,
  },
  {
    id: 4,
    name: "Destination wedding",
    img:
      "assets/photoPack/4.jpg",
      coverage:"Full-day coverage",
      shortDesc:" - 500-600 edited images",
    desc:
      `Morning Photoshoot session of Bride/Groom
Full coverage at the wedding ceremony
Photoshoot of the couple (and wedding train) at a chosen location Reception Coverage
500-600 edited images
40 high-end retouched images
Photobook (30 pages) with best-chosen images
USB drive with all images; shipping cost covered within Italy Transportation/Accommodation on couples`,
  },
  {
    id: 5,
    name: "Court wedding",
    img:
      "assets/photoPack/12.jpg",
      coverage:"3h coverage",
      shortDesc:" - 100 edited images",
    desc:
      `Morning Photoshoot session of Bride
Full coverage of the ceremony
Photoshoot of the couple (and family) at a location
100 edited images
25 high-end retouched images
USB drive with all images; shipping cost covered within Italy Transportation/Accommodation on couples`,
  },
  {
    id: 6,
    name: "Traditional marriage (Engagement)",
    img: "assets/photoPack/6.jpg",
    coverage: "7h coverage",
    shortDesc: " - 200 edited images",
    type: "",
    desc: `Morning Photoshoot session of Bride
Full coverage of the ceremony
Photoshoot of the couple at a chosen location
Photoshoot of couple and family
Reception coverage (within the 10h)
200 edited images
25 high-end retouched images
Photobook (25 pages) with best-chosen images
USB drive with all images; shipping cost covered within Italy`,
  },
];

export const collaborators = [
  {
    id: "1",
    name: "Mamot Fammedia Films",
    nation: "Italy",
    desc: "2 Brothers in love with weddings",
    instagram: "mamotfammedia",
    mail: "mamotfammedia@gmail.com",
    img: "assets/collaborators/10.jpg",
  },
  {
    id: "2",
    name: "Efya Bilson",
    nation: "Italy",
    instagram: "efya_bilson",
    mail: "efia.bilson@gmail.com",
    desc: "Wedding Coordinator and MC",
    img: "assets/collaborators/9.jpg",
  },
  {
    id: "3",
    name: "Debbi's Events / Debbie's Decor",
    nation: "Italy",
    instagram: "debbisdecor",
    mail: "debbisevents@gmail.com",
    number: "393293910089",
    desc: "Event Decor and planner",
    img: "assets/collaborators/2.jpeg",
  },
  {
    id: "4",
    name: "Hetty’s Beauty Parlour",
    nation: "Italy",
    instagram: "hettysbeautyparlour",
    mail: "hettysbeautyparlour@gmail.com",
    desc: "-",
    website: "https://hettysbeautyparlour.com/",
    img: "assets/collaborators/8.jpg",
  },
  {
    id: "5",
    name: "Adjoa's Paradise",
    nation: "Italy",
    instagram: "adjoaparadise",
    mail: "adjoasparadise@yahoo.com",
    desc: "Wedding Makeup Artist",
    img: "assets/collaborators/7.jpg",
  },

  {
    id: "6",
    name: "The Royal Weddings and Events",
    nation: "Italy",
    instagram: "royalweddingsby_ru",
    mail: "Esther_onos17@yahoo.com",
    desc: "Wedding and event planner",
    img: "assets/collaborators/1.jpg",
  },
  {
    id: "7",
    name: "David Foster Woode",
    nation: "Netherlands",
    mail: "info@davidfwoode.com",
    website: "https://davidfwoode.com/",
    instagram: "davidfwoode",
    desc: "Fashion Photographer",
    img: "assets/collaborators/5.jpg",
  },
  {
    id: "8",
    name: "Big Shaky Photography",
    nation: "United Kingdom",
    instagram: "bigshakyphotography",
    mail: "bigshakypictures@yahoo.it",
    desc: "Wedding Photographer - We just bring out your real beauty",
    img: "assets/collaborators/4.jpeg",
  },
  
];

export const portraits = {
  horizonatalPictures: [8],
  format23: [
    3, 4, 6, 12, 13, 14, 15, 18, 21, 24, 34, 37, 38, 39, 41, 45, 46, 53,
  ],
  picturesCount: 54,
  folder: "potraits",
};

export const brand = {
  horizonatalPictures: [3, 5],
  format23: [1, 4, 5, 7],
  picturesCount: 16,
  folder: "brand",
};

export const weddings = [
  {
    title: "Claudio & Flavia",
    mainPicture: "assets/weddings/Claudio_Flavia/38.jpg",
    verticalPictures: [
      2, 6, 8, 9, 10, 11, 12, 13, 14, 15, 17, 19, 21, 27, 32, 33, 34, 35, 36,
      37, 38, 42, 43, 44, 45, 46, 47, 48, 50,
    ],
    picturesCount: 50,
    format23: [
      1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 40, 41,
      42, 43, 44, 45, 46, 47, 49, 50,
    ],
    description:
      "Some decisions shape and transform the life of a man and deciding to spend the rest of my life with you is what has transformed me until this day. Cheers to a married life. ",
    folder: "Claudio_Flavia",
  },
  {
    title: "Esther & Harry",
    mainPicture: "assets/weddings/Esther_Harry/30.jpg",
    verticalPictures: [
      1, 2, 7, 8, 10, 11, 12, 13, 14, 15, 27, 28, 29, 30, 31, 32, 33,
    ],
    picturesCount: 33,
    format23: [
      1, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 19, 20, 21, 22, 23,
      24, 25, 26, 27, 28, 29, 30, 31, 32, 33,
    ],
    description:
      "When you find the person you want to spend the rest of your life with , you want the rest of your life to start today!!!. Cheers to the Rest of our lives",
    folder: "Esther_Harry",
  },
  {
    title: "Lola & Giacomo",
    mainPicture: "assets/weddings/Lola_Giacomo/7.jpg",
    verticalPictures: [6, 7, 8, 9, 10, 11, 12],
    format23: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    description:
      "A new chapter in our lives starts today and it is one that we will fill its pages with joy and laughter, peace and happiness, smiles and lots of smiles.",
    picturesCount: 12,
    folder: "Lola_Giacomo",
  },
  {
    title: "Angela & Ismailia",
    mainPicture: "assets/weddings/Angela_Ismalia/1.jpg",
    verticalPictures: [
      3, 5, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
      26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    ],
    format23: [18, 19],
    description:
      "It’s an honor to share life with my best friend. Enjoying the journey along the way and arriving at every destination together.",
    picturesCount: 40,
    folder: "Angela_Ismalia",
  },
  {
    title: "Romeo & Linda",
    mainPicture: "assets/weddings/Romeo_Linda/36.jpg",
    verticalPictures: [
      1, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 14, 15, 17, 19, 20, 21, 22, 23, 24,
      25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 37, 38, 39, 40, 41, 42, 45,
      46, 48,
    ],
    format23: [18, 19],
    description:
      "I am looking forward to falling in love with you over and over again. Even through the bad, ugly or worse. I will be beside you till death do us part.",
    picturesCount: 51,
    folder: "Romeo_Linda",
  },
  {
    title: "Joel & Vera",
    mainPicture: "assets/weddings/Joel_Vera/19.jpg",
    verticalPictures: [
      4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 17, 18, 19, 20, 21, 22, 23, 24,
      25, 26, 27, 28, 29, 30, 31, 32, 33, 36, 37, 38, 39, 40, 41,
    ],
    format23: [1, 2, 9, 15],
    description: "TODO",
    picturesCount: 41,
    folder: "Joel_Vera",
  },
  {
    title: "Jackline & James",
    mainPicture: "assets/weddings/Jackline_James/41.jpg",
    verticalPictures: [
      1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 13, 14, 15, 16, 17, 18, 19, 20, 21, 23,
      24, 25, 26, 28, 29, 31, 32, 33, 34, 35, 36, 37, 38,
    ],
    format23: [7, 8, 22],
    description: `On our special day, we were so blessed that it even started raining; if that wasn't a sign then I don't know. "Showers of blessing" that's exactly what it was. Everything worked in our favour. His grace and love was so strong that we could feel it. What God has put together, let no man put asunder. One of the best day's of our lives.`,
    picturesCount: 42,
    folder: "Jackline_James",
  },
];
