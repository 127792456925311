import "./aboutMe.scss";

export default function AboutMe() {

  return (
    <div className="aboutMe" id="aboutMe">
      <div className="d-flex justify-content-between w-100 align-items-center">
        <div className="title-line"></div>
        <h1>About Me</h1>
        <div className="title-line"></div>
      </div>
      <div className="d-flex flex-row light">
        <div className="left">
          <div className="wrapper">
            <div>The beauty of seeing reality is sometimes just a glimpse of purely expressed emotions immortalized in time with a shot; this would be my definition of Photography.
              <br />Photography started as a way of viewing the world through the lens of my eyes but then, it quickly transformed into a creative process. A process that leaves you with an experience, a mark, a memory, an alluring souvenir.

              <br />My love for photography started in New York City in 2015. If you know, then you know New York has a way of changing your perspectives and presenting you with diverse opportunities. The opportunity to capture beautiful and memorable moments.

              <br />My images are unique, not because I say so myself but because my open-mindedness, passions and my lifestyle have greatly affected my perspective. This perspective shift makes me look at photography differently, it is not just an image. It is an experience.

              <br />When shooting for weddings, fashion, beauty, pregnancy, family and for brands, the need of the client is my priority. I make sure to deliver as the client pleases while adding the “Nev touch”. You would have to book me to find out what that is.
            </div>
            <br />
            <h5>Immortalize the moments. Let’s create memories together.</h5>
          </div>
        </div>
        <div className="right">
          <div className="imgContainer">
            <img src="assets/_MG_8147.JPG" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
