import "./footer.scss";

export default function Footer() {
  return (
    <div className="footer">
      <a className="icon-container ml-auto" target="_blank" rel="noreferrer" href="mailto:info.nevison@gmail.com">
      <i className="fa fa-envelope"></i>
      </a>
      <a className="icon-container" target="_blank" rel="noreferrer" href="https://www.instagram.com/nevisonwillis/">
      <i className="fa fa-instagram"></i>
       </a>
      <a className="icon-container mr-auto" target="_blank" rel="noreferrer" href="https://www.facebook.com/Nevison.wilson">
      <i className="fa fa-facebook"></i>
             </a>
    </div>
  );
}
