import React,{ useState, useCallback } from "react";
import "./brand.scss";
import {brand} from "../../data";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import _ from "lodash";


export default function Brand() {
 
  

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const getViews= item=>{
    let views=[];
              for (let i=1; i<item.picturesCount; i++){
                views.push({
                  src: "assets/"+item.folder+"/"+i+".jpg",
                  width: _.includes(item.horizonatalPictures, i)?_.includes(item.format23, i)? 3 :4:_.includes(item.format23, i)?2:3,
                  height: _.includes(item.horizonatalPictures, i)? _.includes(item.format23, i)?2:3:_.includes(item.format23, i)?3:4,
                 //srcSet: "assets/"+item.folder+"/",
                  caption: "Brand"
                })
              }
              return views
  }

  return (
    <div className="brand" id="brand">
<div className="d-flex justify-content-between w-100 align-items-center">
        <div className="title-line"></div>
        <h1>Brand</h1>
        <div className="title-line"></div>
      </div>        
      <div className="h-100 w-100">         
    
      <Gallery photos={getViews(brand)} onClick={openLightbox} direction={"column"} />
              
      </div>  <ModalGateway>
              {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
              <Carousel
              currentIndex={currentImage}
              views={getViews(brand)}
            />
              </Modal>
            ) : null}
              </ModalGateway>
        
    </div>
  );
}
