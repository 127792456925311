import "./menu.scss";
import { useLocation } from 'react-router-dom'


export default function Menu({ menuOpen, setMenuOpen }) {
  const location = useLocation();

 
  return (
    <div className={"menu "+(menuOpen && "active")}>
      <ul>
        <li onClick={()=>setMenuOpen(false)}>
          <a href={location.pathname==="/"?"#intro":"./#intro"}>Home</a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
        <a href={location.pathname==="/"?"#portfolio":"./#portfolio"}>Portfolio</a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
        <a href={location.pathname==="/"?"#photoPackages":"./#photoPackages"}>Packages</a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
        <a href={location.pathname==="/"?"#aboutMe":"./#aboutMe"}>About Me</a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
        <a href={location.pathname==="/"?"#collaborators":"./#collaborators"}>Collaborators</a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
        <a href={location.pathname==="/"?"#contact":"./#contact"}>Contact</a>
        </li>
      </ul>
    </div>
  );
}
