import { useState } from "react";
import "./collaborators.scss";
import _ from "lodash"
import {collaborators} from "../../data"

export default function Collaborators() {
  const [currentSlide, setCurrentSlide] = useState(0);
  


  return (
    <div className="collaborators" id="collaborators">
      <div className="d-flex justify-content-between w-100 align-items-center">
        <div className="title-line"></div>
        <h1>Collaborators</h1>
        <div className="title-line"></div>
      </div>
      <div className="container-list">
      {collaborators.map((d) => (
        <div className={`item-container ${d.id % 2 != 0 ? "pink-mobile" : ""} ${(d.id == 2 || d.id == 3 || d.id == 6|| d.id == 7) ? "pink" : ""}`}>
          <div className="item">
            <div className={`right ${d.id % 2 != 0 ? "d-none-picture" : ""}`}>
              
              <img
                className="main-picture"
                src={d.img}
                alt=""
              />
              <div className="p-2 icon-list">
                <a className={`icon-container mr-auto ${_.isNil(d.mail) ? "d-none" : ""}`} href={`mailto:${d.mail}`}>
                  <i className="fa fa-envelope"></i>
                </a>
                <a className={`icon-container mr-auto ${_.isNil(d.instagram)? "d-none" : ""}`} href={`https://www.instagram.com/${d.instagram}/`}>
                  <i className="fa fa-instagram"></i>
                </a>
                <a className={`icon-container mr-auto ${_.isNil(d.number) ? "d-none" : ""}`} href={`https://wa.me/${d.number}/`}>
                  <i className="fa fa-whatsapp"></i>
                </a>
                <a className={`icon-container mr-auto ${_.isNil(d.youtube) ? "d-none" : ""}`} href={d.youtube}>
                  <i className="fa fa-youtube"></i>
                </a>
                <a className={`icon-container mr-auto ${_.isNil(d.website) ? "d-none" : ""}`} href={d.website}>
                  <i className="fa fa-user"></i>
                </a>
              </div>
            </div>
            <div className="left">
              <div className="leftContainer">
                <h3>{d.name}</h3>
                <div className="text-overline">{d.nation}</div>
                <div>{d.desc}</div>
              </div>
            </div>
            <div className={`right bottom-picture ${d.id % 2 === 0 ? "d-none" : ""}`}>
            <div className="p-2 icon-list">
                <a className={`icon-container mr-auto ${_.isNil(d.mail) ? "d-none" : ""}`} target="_blank" rel="noreferrer" href={`mailto:${d.mail}`}>
                  <i className="fa fa-envelope"></i>
                </a>
                <a className={`icon-container mr-auto ${_.isNil(d.instagram)? "d-none" : ""}`} target="_blank" rel="noreferrer" href={`https://www.instagram.com/${d.instagram}/`}>
                  <i className="fa fa-instagram"></i>
                </a>
                <a className={`icon-container mr-auto ${_.isNil(d.number) ? "d-none" : ""}`} target="_blank" rel="noreferrer" href={`https://wa.me/${d.number}/`}>
                  <i className="fa fa-whatsapp"></i>
                </a>
                <a className={`icon-container mr-auto ${_.isNil(d.youtube) ? "d-none" : ""}`} target="_blank" rel="noreferrer" href={d.youtube}>
                  <i className="fa fa-youtube"></i>
                </a>
                <a className={`icon-container mr-auto ${_.isNil(d.website) ? "d-none" : ""}`} target="_blank" rel="noreferrer" href={d.website}>
                  <i className="fa fa-user"></i>
                </a>
              </div>
              <img
                className="main-picture"
                src={d.img}
                alt=""
              />
            </div>
          </div>
        </div>
      ))}
      </div>

    </div>
  );
}
