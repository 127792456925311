import Topbar from "./components/topbar/Topbar";
import Intro from "./components/intro/Intro"
import Portfolio from "./components/portfolio/Portfolio"
import PhotoPackages from "./components/photoPackages/PhotoPackages"
import Contact from "./components/contact/Contact"
import "./app.scss"
import { useState } from "react";
import Menu from "./components/menu/Menu";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import AboutMe from "./components/aboutMe/AboutMe";
import Weddings from "./components/weddings/Weddings";
import Portraits from "./components/potraits/Potraits";
import Brand from "./components/brand/Brand";
import PhotoPack from "./components/photoPack/PhotoPack";
import VideoPack from "./components/videoPack/VideoPack";
import Footer from "./components/footer/Footer";
import Collaborators from "./components/collaborators/Collaborators";


function App() {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <Router>
      <div className="app">
        <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        <Routes>
          <Route exact path="/" element={ <div className="sections">
              <Intro />
              <Portfolio />
              <PhotoPackages />
              <AboutMe />
              <Collaborators/>
              <Contact />
            </div> }>
                    
          </Route>
          <Route path="/weddings" element={ <Weddings />}></Route>
          <Route path="/portraits" element={ <Portraits />}></Route>
          <Route path="/brand" element={ <Brand />}></Route>
          <Route path="/photo-pack" element={ <PhotoPack />}></Route>
          <Route path="/video-pack" element={ <VideoPack />}></Route>
           
          
        </Routes>
    <Footer/>
      </div>
    </Router>
  );
}

export default App;
