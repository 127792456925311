import "./photoPackages.scss";
import { Link } from "react-router-dom";
import { packages } from "../../data"
import { Button, Modal } from "react-bootstrap"
import React, { useState } from "react";



export default function PhotoPackages() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  return (
    <div className="photoPackages" id="photoPackages">
      <div className="d-flex justify-content-between w-100 align-items-center">
        <div className="title-line"></div>
        <h1>Packages</h1>
        <div className="title-line"></div>
      </div>
      <div className="container">
        {packages.map((d) => 
          {if(d.id==3){
            return <div onClick={() => handleShow()} className={d.featured ? "card featured" : "card c-pointer"}>
            <div className="top">
              <img
                className="preview"
                src={d.img}
                alt=""
              />
            </div>
            <div className="bottom">
              <h4 className="text-center">{d.name}</h4>
              <div className="text-center">{d.desc}</div>
            </div>
          </div>
          } else{
            return <Link to={d.url}  className={d.featured ? "card featured" : "card"}>
            <div className="top">
              <img
                className="preview"
                src={d.img}
                alt=""
              />
            </div>
            <div className="bottom">
              <h4 className="text-center">{d.name}</h4>
              <div className="text-center">{d.desc}</div>
            </div>
          </Link>
          }}
          
        )}
      </div>

<Modal show={show} onHide={() => handleClose()}>
<Modal.Header>
  <Modal.Title>Extra Services</Modal.Title>
</Modal.Header>
<Modal.Body>
Pre wedding shoot -1 minute Teaser<br/>
1-2 minute Teaser <br/>
Pre wedding photoshoot <br/>
Bridal Shower <br/>
Photobook<br/>
Extra Retouched images<br/>
</Modal.Body>
<Modal.Footer>
  <Button className="button" onClick={() => handleClose()}>
    Close
  </Button>
</Modal.Footer>
</Modal>    </div>

  );
}
