import "./topbar.scss";
import { useLocation } from 'react-router-dom'

export default function Topbar({ menuOpen, setMenuOpen }) {
  const location = useLocation();
  return (
    <div className={"topbar " + (menuOpen && "active")}>
      <div className="wrapper">
        <div className="left">
          <a href={location.pathname==="/"?"#intro":"./#intro"}>
        <img
        src="assets/logowhite.png"
        className="logo mr-4 pl-3"
        
      /></a>

         <div className="itemContainer">
         <a href={location.pathname==="/"?"#portfolio":"./#portfolio"}>Portfolio</a>
          </div>
         <div className="itemContainer">
         <a href={location.pathname==="/"?"#photoPackages":"./#photoPackages"}>Packages</a>
          </div>
         <div className="itemContainer">
         <a href={location.pathname==="/"?"#aboutMe":"./#aboutMe"}>About Me</a>
          </div>
         <div className="itemContainer">
         <a href={location.pathname==="/"?"#collaborators":"./#collaborators"}>Collaborators</a>
          </div>
          <div className="itemContainer">
          <a href={location.pathname==="/"?"#contact":"./#contact"}>Contact</a>
          </div>
        </div>
        <div className="right">
          <div className="hamburger" onClick={()=>setMenuOpen(!menuOpen)}>
            <span className="line1"></span>
            <span className="line2"></span>
            <span className="line3"></span>
          </div>
        </div>
      </div>
    </div>
  );
}
